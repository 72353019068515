import React, { useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
// import './ccss/sb-admin-2.min.css';
// import './ccss/fontawesome-free/css/all.min.css'
import Sidebar from './SideBar';

const cardImages = {
  DAFA: "/img/dafaLogo.png",
  FUGA: "/img/fugarLogo.png"
};
//test

const DestinationPage = () => {
  const [cookies] = useCookies(['selectedCard']);
  const imageUrl = cardImages[cookies.selectedCard]
  const [isComponentsCollapsed, setIsComponentsCollapsed] = useState(true);
  const [isUtilitiesCollapsed, setIsUtilitiesCollapsed] = useState(true);
  const [isInventario, setisIventatioCollapsed] = useState(true);
  const [isPrevencion, setisPrevencionCollapsed] = useState(true);
  const [isLogistica, setisLogisticaCollapsed] = useState(true);

  const toggleCollapse = (collapseSetter) => {
    collapseSetter(prevState => !prevState);
  };

  return (
    <div id="page-top">
    <Sidebar
      imageUrl={imageUrl}
      isComponentsCollapsed={isComponentsCollapsed}
      toggleCollapse={toggleCollapse}
      setIsComponentsCollapsed={setIsComponentsCollapsed}
      isUtilitiesCollapsed={isUtilitiesCollapsed}
      setIsUtilitiesCollapsed={setIsUtilitiesCollapsed}
      isInventario={isInventario}
      setisIventatioCollapsed={setisIventatioCollapsed}
      isPrevencion={isPrevencion}
      setisPrevencionCollapsed={setisPrevencionCollapsed}
      isLogistica={isLogistica}
      setisLogisticaCollapsed={setisLogisticaCollapsed}
    />
     </div>
     
  );
}

export default DestinationPage;