import React from 'react';
import { Link } from 'react-router-dom';


//test
function Sidebar({ imageUrl, isComponentsCollapsed, toggleCollapse, setIsComponentsCollapsed, isUtilitiesCollapsed, setIsUtilitiesCollapsed, isInventario, setisIventatioCollapsed, isPrevencion, setisPrevencionCollapsed, isLogistica, setisLogisticaCollapsed }) {
    return (
        <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
            <a class="sidebar-brand d-flex align-items-center justify-content-center">
                <Link class="nav-link" to="/Prehome">
                    <img src={imageUrl} alt="Selected Card" style={{ width: '220px', height: 'auto' }} />
                </Link>
            </a>
            <hr class="sidebar-divider my-0" />
            <li class="nav-item">
                <a class="nav-link" onClick={() => toggleCollapse(setIsComponentsCollapsed)}>
                    <i class="fas fa-users"></i>
                    <span>Recursos Humanos</span>
                </a>
                <div class={`collapse ${isComponentsCollapsed ? '' : 'show'}`} aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div class="bg-white py-2 collapse-inner rounded">
                        <h6 class="collapse-header">Seleccione:</h6>
                        <Link class="collapse-item" to="/Contratos"><i className="fas fa-file-signature"></i> Contratos</Link>
                        <Link class="collapse-item" to="/Compensados"><i class="fas fa-file-invoice-dollar"></i>  Compensados</Link>
                        <Link class="collapse-item" to="/Permisos"><i class="fas fa-indent"></i> Permisos</Link>
                        <Link class="collapse-item" to="/cards"><i class="fas fa-file-word"></i> Pacto H.H</Link>
                        <Link class="collapse-item" to="/cards"><i class="fas fa-file-word"></i>  Asistencia</Link>
                        <Link class="collapse-item" to="/Trabajadores"><i class="fas fa-file-word"></i>  Trabajadores</Link>
                    </div>
                </div>
            </li>
            <hr class="sidebar-divider my-0" />
            <li class="nav-item">
                <a class="nav-link" onClick={() => toggleCollapse(setIsUtilitiesCollapsed)}>
                    <i className="fas fa-fw fa-wrench"></i>
                    <span>Contabilidad</span>
                </a>
                <div class={`collapse ${isUtilitiesCollapsed ? '' : 'show'}`} aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Aqui opciones:</h6>
                        <Link className="collapse-item" to="/colors">Opcion1</Link>
                        <Link className="collapse-item" to="/borders">Opcion2</Link>
                        <Link className="collapse-item" to="/animations">Opcion3</Link>
                        <Link className="collapse-item" to="/other">Opcion4</Link>
                    </div>
                </div>
            </li>
            <hr class="sidebar-divider my-0" />
            <li class="nav-item">
                <a class="nav-link" onClick={() => toggleCollapse(setisIventatioCollapsed)}>
                    <i class="fas fa-boxes"></i>
                    <span>Inventario</span>
                </a>
                <div class={`collapse ${isInventario ? '' : 'show'}`} aria-labelledby="headingUtilitie" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Aqui opciones:</h6>
                        <Link className="collapse-item" to="/colors">Opcion1</Link>
                        <Link className="collapse-item" to="/borders">Opcion2</Link>
                        <Link className="collapse-item" to="/animations">Opcion3</Link>
                        <Link className="collapse-item" to="/other">Opcion4</Link>
                    </div>
                </div>
            </li>
            <hr class="sidebar-divider my-0" />
            <li class="nav-item">
                <a class="nav-link" onClick={() => toggleCollapse(setisPrevencionCollapsed)}>
                    <i class="fas fa-hard-hat"></i>
                    <span>Prevención</span>
                </a>
                <div class={`collapse ${isPrevencion ? '' : 'show'}`} aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Aqui opciones:</h6>
                        <Link className="collapse-item" to="/colors">Opcion1</Link>
                        <Link className="collapse-item" to="/borders">Opcion2</Link>
                        <Link className="collapse-item" to="/animations">Opcion3</Link>
                        <Link className="collapse-item" to="/other">Opcion4</Link>
                    </div>
                </div>
            </li>
            <hr class="sidebar-divider my-0" />
            <li class="nav-item">
                <a class="nav-link" onClick={() => toggleCollapse(setisLogisticaCollapsed)}>
                    <i class="fas fa-truck-loading"></i>
                    <span>Logística</span>
                </a>
                <div class={`collapse ${isLogistica ? '' : 'show'}`} aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                    <div class="bg-white py-2 collapse-inner rounded">
                        <h6 class="collapse-header">Aqui opciones:</h6>
                        <Link class="collapse-item" to="/colors">Opcion1</Link>
                        <Link class="collapse-item" to="/borders">Opcion2</Link>
                        <Link class="collapse-item" to="/animations">Opcion3</Link>
                        <Link class="collapse-item" to="/other">Opcion4</Link>
                    </div>
                </div>
            </li>
            <hr class="sidebar-divider d-none d-md-block" />
            <div className="text-center d-none d-md-inline">
                <button className="rounded-circle border-0" id="sidebarToggle" onClick={() => toggleCollapse(isComponentsCollapsed)}></button>
            </div>
        </ul>

    );
}

export default Sidebar;