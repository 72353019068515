import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ValidatingLogin from './Validations/LoginValidation';
import { useCookies } from 'react-cookie';
import Axios from 'axios';

function Login() {
    const [values, setValues] = useState({
        usuario: '',
        password: ''
    })
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['usuario']);
    const [errors, setErrors] = useState({})
    const handleInput = (event) => {
        setValues(prev => ({ ...prev, [event.target.name]: [event.target.value] }))
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        const validationErrors = ValidatingLogin(values);
        setErrors(validationErrors);
        
        // Verificar si no hay errores directamente después de la validación
        if (!validationErrors.usuario && !validationErrors.password) {
            // Axios.post('http://localhost:3000/login', values)
            Axios.post('https://idafa-connect.cl/login', values)
                .then(res => {
                    console.log(res.data); // Para ver qué está devolviendo el servidor
                    if (res.data === "Success") {
                        const username = values.usuario;
                        setCookie("usuario", username);
                        navigate('/prehome'); // Navegar si el login es exitoso                   
                    } else {
                        alert("Usuario no existe");
                        console.log(res);
                    }
                })
                .catch(err => {
                    console.error("Error en la solicitud:", err);
                });
        }
    };

    return (
        <div className="login-container d-flex align-items-center justify-content-center bg-gradient-primary">
            <div className="login-form p-4 rounded shadow">
                <form onSubmit={handleSubmit} className="user">
                    <div className="text-center mb-4">
                        <img src="/img/IDAFA.png" alt="logo" style={{ maxWidth: '100%', height: 'auto', width: '150px' }} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="usuario">Usuario</label>
                        <input
                            name="usuario"
                            placeholder="Ingrese nombre de usuario"
                            className="form-control"
                            onChange={handleInput}
                        />
                        {errors.usuario && <span className="text-danger">{errors.usuario}</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Contraseña</label>
                        <input
                            name="password"
                            placeholder="Ingrese contraseña"
                            type="password"
                            className="form-control"
                            onChange={handleInput}
                        />
                        {errors.password && <span className="text-danger">{errors.password}</span>}
                    </div>
                    <button type="submit" className="btn btn-primary w-100">Entrar</button>
                </form>
            </div>
        </div>
    )
}

export default Login
