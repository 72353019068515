// GenerarPDF.js
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const generarPDF = async (logo, nombreTrabajador, fechaPermiso, tipoPermiso, horaDesde, HoraHasta) => {
    // Función para convertir la imagen en base64
    const convertImageToBase64 = async (imageUrl) => {
        const response = await fetch(imageUrl);
        const blob = await response.blob();  // Convertir a blob
        const reader = new FileReader();
    
        return new Promise((resolve, reject) => {
            reader.onloadend = () => resolve(reader.result);  // Resolver con base64
            reader.onerror = reject;
            reader.readAsDataURL(blob);  // Leer la imagen como base64
        });
    };

    const doc = new jsPDF();
    const logo64 = await convertImageToBase64(logo);
    const currentDate = new Date();
    const diaTomaCompensado = `${currentDate.getDate().toString().padStart(2, '0')}/${(currentDate.getMonth() + 1).toString().padStart(2, '0')}/${currentDate.getFullYear()}`;
    // Título del PDF
    doc.setFontSize(16);
    
    // Crear la tabla
    autoTable(doc, {
        startY: 20, // Posición de inicio de la tabla
        body: [
            // Agregar imagen en la primera celda del encabezado
            [{ content: '', image: logo64, imageWidth: 50, imageHeight: 20, align: 'center' }, 'COMPROBANTE PERMISO'],
            ['NOMBRE TRABAJADOR', nombreTrabajador],  // Fila 2
            ['Tipo comprobante', "C.C empleador"],   // Fila 3
            ['Tipo permiso', tipoPermiso === 'DC' ? 'Dia completo' : tipoPermiso === 'RH' ? 'Rango horario' : ''],
            ['', ''],
        ],
        theme: 'grid', // Tema de la tabla, con bordes visibles
        styles: {
            halign: 'center',  // Alineación horizontal
            valign: 'middle',  // Alineación vertical
            cellPadding: 10,   // Aumentar relleno para aumentar el tamaño de la celda
            lineColor: [0, 0, 0], // Color de borde
            lineWidth: 0.5,       // Ancho del borde
        },
        headStyles: {
            fillColor: [255, 255, 255], // Fondo blanco para los encabezados
            textColor: [0, 0, 0],       // Color de texto negro
        },
        columnStyles: {
            0: { cellWidth: 85, fillColor: [255, 255, 255], textColor: [0, 0, 0] }, // Columna 1 (con imagen)
            1: { cellWidth: 90, fillColor: [255, 255, 255], textColor: [0, 0, 0] }  // Columna 2
        },
        didDrawCell: (data) => {
            if (data.row.index === 0 && data.column.index === 0) {
                // Modificar la altura de la celda donde aparece la imagen (en la primera fila y columna)
                doc.addImage(logo64, 'PNG', data.cell.x + 2, data.cell.y + 2, 80, 20); // Insertar la imagen
                data.cell.height = 30; // Modificar la altura de la celda de la primera columna (ajustar según sea necesario)
            }
            if (data.row.index === 4 && data.column.index === 0) {
                // Subdividir la columna en dos celdas
                doc.text('FIRMA TRABAJADOR', data.cell.x + 25, data.cell.y + 22); // Colocar FIRMA TRABAJADOR
                data.cell.height = 30; // Ajustar altura de la celda de firma
            }
            if (data.row.index === 4 && data.column.index === 1) {
                if (tipoPermiso === "DC") {
                    // Si tipoComprobante es "DC", mostramos "Fecha Permiso"
                    doc.text('Fecha Permiso: ' + fechaPermiso, data.cell.x + 25, data.cell.y + 13);
                  } else if (tipoPermiso === "RH") {
                    // Si tipoComprobante es "RH", mostramos los horarios (como en el código original)
                    const middleY = data.cell.y + data.cell.height / 2;  // Punto medio en la dirección Y
                
                    // Escribir el texto en la primera mitad de la celda
                    doc.text('Desde las: ' + horaDesde + ' hrs.', data.cell.x + 30, data.cell.y + 7);
                
                    // Escribir el texto en la segunda mitad de la celda
                    doc.text('Hasta las: ' + HoraHasta + ' hrs.', data.cell.x + 30, middleY + 7);
                
                    // Dibujar la línea divisoria entre las dos mitades (horizontal)
                    doc.setLineWidth(0.5);
                    doc.line(data.cell.x, middleY, data.cell.x + data.cell.width, middleY); // Línea horizontal
                  }
            }
        }
    });

    const lineY = doc.lastAutoTable.finalY + 10;  // Usar la posición final de la tabla más un margen
    doc.setLineDash([4, 2]); 
    doc.line(10, lineY, doc.internal.pageSize.width - 10, lineY); // Línea horizontal a lo largo de la página
    // Desactivar el estilo de línea segmentada para la segunda tabla
    doc.setLineDash([]); // Restablecer el estilo de línea a sólido

    autoTable(doc, {
        startY: 160, // Posición de inicio de la tabla
        body: [
            // Agregar imagen en la primera celda del encabezado
            [{ content: '', image: logo64, imageWidth: 50, imageHeight: 20, align: 'center' }, 'COMPROBANTE PERMISO'],
            ['NOMBRE TRABAJADOR', nombreTrabajador],  // Fila 2
            ['Tipo comprobante', "C.C trabajador"],   // Fila 3
            ['Tipo permiso', tipoPermiso === 'DC' ? 'Dia completo' : tipoPermiso === 'RH' ? 'Rango horario' : ''],
            ['', ''],
        ],
        theme: 'grid', // Tema de la tabla, con bordes visibles
        styles: {
            halign: 'center',  // Alineación horizontal
            valign: 'middle',  // Alineación vertical
            cellPadding: 10,   // Aumentar relleno para aumentar el tamaño de la celda
            lineColor: [0, 0, 0], // Color de borde
            lineWidth: 0.5,       // Ancho del borde
        },
        headStyles: {
            fillColor: [255, 255, 255], // Fondo blanco para los encabezados
            textColor: [0, 0, 0],       // Color de texto negro
        },
        columnStyles: {
            0: { cellWidth: 85, fillColor: [255, 255, 255], textColor: [0, 0, 0] }, // Columna 1 (con imagen)
            1: { cellWidth: 90, fillColor: [255, 255, 255], textColor: [0, 0, 0] }  // Columna 2
        },
        didDrawCell: (data) => {
            if (data.row.index === 0 && data.column.index === 0) {
                // Modificar la altura de la celda donde aparece la imagen (en la primera fila y columna)
                doc.addImage(logo64, 'PNG', data.cell.x + 2, data.cell.y + 2, 80, 20); // Insertar la imagen
                data.cell.height = 30; // Modificar la altura de la celda de la primera columna (ajustar según sea necesario)
            }
            if (data.row.index === 4 && data.column.index === 0) {
                // Subdividir la columna en dos celdas
                doc.text('FIRMA TRABAJADOR', data.cell.x + 25, data.cell.y + 22); // Colocar FIRMA TRABAJADOR
                data.cell.height = 30; // Ajustar altura de la celda de firma
            }
            if (data.row.index === 4 && data.column.index === 1) {
                if (tipoPermiso === "DC") {
                    // Si tipoComprobante es "DC", mostramos "Fecha Permiso"
                    doc.text('Fecha Permiso: ' + fechaPermiso, data.cell.x + 25, data.cell.y + 13);
                  } else if (tipoPermiso === "RH") {
                    // Si tipoComprobante es "RH", mostramos los horarios (como en el código original)
                    const middleY = data.cell.y + data.cell.height / 2;  // Punto medio en la dirección Y
                
                    // Escribir el texto en la primera mitad de la celda
                    doc.text('Desde las: ' + horaDesde + ' hrs.', data.cell.x + 30, data.cell.y + 7);
                
                    // Escribir el texto en la segunda mitad de la celda
                    doc.text('Hasta las: ' + HoraHasta + ' hrs.', data.cell.x + 30, middleY + 7);
                
                    // Dibujar la línea divisoria entre las dos mitades (horizontal)
                    doc.setLineWidth(0.5);
                    doc.line(data.cell.x, middleY, data.cell.x + data.cell.width, middleY); // Línea horizontal
                  }
            }
        }
    });

    // Guardar el PDF
    doc.save("Comprobante permiso " + nombreTrabajador + " " + fechaPermiso +".pdf");
};

export default generarPDF;
