import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCookies } from 'react-cookie';
import { Link, useNavigate } from 'react-router-dom';
import Axios from 'axios';

const App = () => {
  const [selectedCard, setSelectedCard] = React.useState(null);
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['selectedCard']);
  const [EmpresasList, setEmpresas] = useState([]);

  const handleSelectCard = (ID, cardName, NombreEmpresa) => {
    setSelectedCard(ID);
    setCookie('selectedCard', cardName, { path: '/' });
    setCookie('idEmpresa', ID);
    setCookie('NombreEmpresa', NombreEmpresa);
    navigate('/home');
  };

  const getTrabajadores = () => {
    Axios.get('https://idafa-connect.cl/getEmpresas')
    // Axios.get('http://localhost:3000/getEmpresas')
      .then(res => {
        console.log(res.data); // Verifica la estructura de la respuesta
        if (Array.isArray(res.data)) {
          setEmpresas(res.data); // Asegúrate de que sea un array
        } else {
          console.error('La respuesta no es un array:', res.data);
          setEmpresas([]); // Establece un array vacío si no es un array
        }
      })
      .catch(err => {
        console.error("Error al obtener los datos:", err);
      });
  }

  const formatRut = (rut) => {
    const rutString = rut.toString();
    // Extrae el dígito verificador (último dígito) y los demás números
    const body = rutString.slice(0, -1);
    const verifier = rutString.slice(-1);
    // Aplica puntos cada tres dígitos al cuerpo del RUT
    const formattedBody = body.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    // Retorna el RUT formateado con el guion antes del dígito verificador
    return `${formattedBody}-${verifier}`;
  };

  useEffect(() => {
    getTrabajadores();
  }, []);

  return (
    <div className="container-fluid vh-100 d-flex justify-content-center align-items-center bg-gradient-primary">
      <div className="col-md-8 col-lg-6">
        <div className="card p-3 shadow-sm">
          <table className="table table-striped table-hover table-bordered text-center">
            <thead className="thead-light">
              <tr>
                <th>RUT</th>
                <th>Empresa</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {EmpresasList.map((EmpresasList) => (
                <tr key={EmpresasList.ID_Empresa}>
                  <td>{formatRut(EmpresasList.Rut_Empresa)}</td>
                  <td>{EmpresasList.Nombre_Empresa}</td>
                  <td>
                    <a href="" onClick={() => handleSelectCard(EmpresasList.ID_Empresa, EmpresasList.Nombre_Empresa.substring(0, 4), EmpresasList.Nombre_Empresa)}>Entrar</a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>

  );
}

export default App;