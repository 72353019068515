import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ccss/sb-admin-2.min.css';
import './ccss/fontawesome-free/css/all.min.css'
import Login from './Login';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Registro from './Registro'
import Home from './Home'
import Prehome from './Prehome'
import Trabajadores from './Trabajadores'
import Contratos from './Contratos'
import Compensados from './Compensados'
import Permisos from './Permisos';

function App() {
  return (
   <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />}></Route>
        <Route path='/registro' element={<Registro />}></Route>
        <Route path='/home' element={<Home />}></Route>
        <Route path='/Prehome' element={<Prehome />}></Route>
        <Route path='/Trabajadores' element={<Trabajadores />}></Route>
        <Route path='/Contratos' element={<Contratos />}></Route>
        <Route path='/Login' element={<Login />}></Route>
        <Route path='/Compensados' element={<Compensados />}></Route>
        <Route path='/Permisos' element={<Permisos />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;