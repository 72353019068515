// /Validations/TrabajadoresValidation.js
export const validarCampos = (campos) => {
  const {
    empresa, nombres, apellidos, nacionalidad, fechaNacimiento, estadoCivil,
    afp, rut, prevision, domicilio, comuna, cargo, remuneracion,
    tipoContrato, vigenciaDesde, vigenciaHasta
  } = campos;

  if (
    !empresa || !nombres || !apellidos || !nacionalidad || !fechaNacimiento ||
    !estadoCivil || afp === "none" || afp === "Seleccione:" || !rut || prevision === "none" ||
    prevision === "Seleccione:" || !domicilio || !comuna || !cargo || !remuneracion ||
    tipoContrato === "none" || tipoContrato === "Seleccione:" || !vigenciaDesde || !vigenciaHasta
  ) {
    return false;
  }
  return true;
};

// /Validations/ArchivoValidation.js
export const validarArchivo = (selectedFile, TipoArchivo) => {
  if (!selectedFile) {
    return 'Por favor, selecciona un archivo antes de subir.';
  }

  if (!TipoArchivo || TipoArchivo === "Seleccione:") {
    return 'Por favor, selecciona un tipo de archivo válido.';
  }

  return ''; // Retorna una cadena vacía si todas las validaciones pasan
};

export const formatRut = (value) => {
  // Remueve cualquier caracter que no sea número o 'K' (dígito verificador)
  value = value.replace(/[^0-9kK]/g, '').toUpperCase();

  // Inserta el guion antes del dígito verificador
  if (value.length > 1) {
    value = value.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + '-' + value.slice(-1);
  }

  return value;
};

export const cleanRut = (formattedRut) => {
  return formattedRut.replace(/\D/g, '').slice(0, -1); // Remueve caracteres no numéricos y el dígito verificador
};

export const formatRemuneracion = (value) => {
  // Remueve cualquier caracter que no sea número
  value = value.replace(/\D/g, '');

  // Formatea en miles
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const cleanRemuneracion = (formattedRemuneracion) => {
  return formattedRemuneracion.replace(/\./g, ''); // Elimina puntos
};

export const validarCompensado = (Fecha, TipoCompensado) => {
  if (!Fecha) {
    return 'Por favor, seleccione la fecha del compensado!';
  }

  if (!TipoCompensado || TipoCompensado === "Seleccione:") {
    return 'Por favor, seleccione el tipo de compensado.';
  }

  return ''; // Retorna una cadena vacía si todas las validaciones pasan
};

export const validarPermiso = (Fecha, tipoPermiso, HoraDesde, HoraHasta) => {
  if (!Fecha) {
    return 'Por favor, seleccione la fecha del permiso!';
  }

  if (!tipoPermiso || tipoPermiso === "Seleccione:") {
    return 'Por favor, seleccione el tipo de permiso.';
  }

  if (tipoPermiso === "RH") {
    if (!HoraDesde) {
      return 'Por favor, seleccione la hora de inicio del permiso!';
    }

    if (!HoraHasta) {
      return 'Por favor, seleccione la hora de termino del permiso!';
    }
  }
  
  return ''; // Retorna una cadena vacía si todas las validaciones pasan
};